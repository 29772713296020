/* Global Stiller */
:root {
  --surface-ground: #f8f9fa;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dfe7ef;
  --primary-color: #4339F2;
  --primary-dark: #3730E1;
  --text-color: #495057;
  --text-color-secondary: #6c757d;
  --menu-bg: #ffffff;
  --menu-text-color: #495057;
  --topbar-height: 70px;
}

:root.dark {
  --surface-ground: #17212f;
  --surface-section: #1b2a3b;
  --surface-card: #1b2a3b;
  --surface-overlay: #1b2a3b;
  --surface-border: #304562;
  --text-color: #cbd5e1;
  --text-color-secondary: #94a3b8;
  --menu-bg: #1b2a3b;
  --menu-text-color: #cbd5e1;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--surface-ground);
  color: var(--text-color);
}

/* Login Sayfası Stilleri */
.login-container {
  display: flex;
  min-height: 100vh;
}

.login-left {
  flex: 1;
  background-color: var(--primary-color);
  color: white;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.login-logo {
  font-size: 2rem;
  margin-bottom: 4rem;
}

.login-left h1 {
  font-size: 3.5rem;
  line-height: 1.2;
  margin-bottom: 2rem;
}

.login-left p {
  font-size: 1.25rem;
  line-height: 1.6;
  opacity: 0.9;
  max-width: 500px;
}

.login-copyright {
  position: absolute;
  bottom: 2rem;
  opacity: 0.7;
}

.login-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: var(--surface-section);
}

.login-box {
  width: 100%;
  max-width: 400px;
}

.login-header {
  margin-bottom: 2rem;
}

.login-header h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--text-color);
}

.login-header h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--text-color);
}

.login-header p {
  color: var(--text-color-secondary);
  line-height: 1.5;
}

.login-header a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
}

.login-box .field {
  margin-bottom: 1.5rem;
}

.login-box .p-inputtext,
.login-box .p-password input {
  width: 100%;
  padding: 1rem;
  border: 1px solid var(--surface-border);
  border-radius: 0.5rem;
  background-color: var(--surface-card);
  color: var(--text-color);
}

.login-box .p-password {
  width: 100%;
}

.login-button {
  width: 100%;
  padding: 1rem;
  background-color: var(--primary-color);
  border: none;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
  transition: background-color .2s;
}

.login-button:hover {
  background-color: var(--primary-dark);
}

.google-button {
  width: 100%;
  padding: 1rem;
  border: 1px solid var(--surface-border);
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  background-color: var(--surface-card);
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color .2s;
}

.google-button:hover {
  background-color: var(--surface-section);
}

.google-button .pi {
  color: #DB4437;
}

.forgot-password {
  text-align: center;
  color: var(--text-color-secondary);
}

.forgot-password a {
  color: var(--text-color);
  text-decoration: none;
  margin-left: 0.5rem;
}

/* Layout Stiller */
.layout-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Topbar Stiller */
.layout-topbar {
  height: var(--topbar-height);
  background-color: var(--surface-card);
  border-bottom: 1px solid var(--surface-border);
  display: flex;
  align-items: center;
  padding: 0 2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.layout-topbar-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.layout-topbar-logo {
  display: flex;
  align-items: center;
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: 600;
  text-decoration: none;
}

.layout-menu-button {
  display: none;
}

.layout-topbar-mobile-button {
  display: none;
}

.layout-topbar-right {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.layout-topbar-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* Menu Stiller */
.layout-menu-container {
  position: fixed;
  top: var(--topbar-height);
  left: 0;
  width: 300px;
  height: calc(100vh - var(--topbar-height));
  background-color: var(--menu-bg);
  border-right: 1px solid var(--surface-border);
  overflow-y: auto;
  z-index: 998;
  transition: transform .2s;
}

.layout-menu {
  padding: 1.5rem;
}

.layout-menu-category {
  margin-bottom: 1.5rem;
}

.layout-menu-category-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 0.5rem;
  color: var(--text-color-secondary);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.875rem;
}

.layout-menu-category-header i {
  font-size: 1rem;
}

.layout-menu-category-items {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.layout-menu-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  color: var(--menu-text-color);
  text-decoration: none;
  border-radius: 12px;
  transition: all .2s;
  position: relative;
}

.layout-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.layout-menu-item.active-route {
  background-color: var(--primary-color);
  color: #ffffff;
}

.layout-menu-item i {
  font-size: 1.25rem;
}

/* Main Content Stiller */
.layout-main {
  margin-left: 300px;
  margin-top: var(--topbar-height);
  min-height: calc(100vh - var(--topbar-height));
  background-color: var(--surface-ground);
  transition: margin-left .2s;
}

.layout-content {
  padding: 2rem;
}

/* Mask */
.layout-mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 997;
}

/* Responsive Stiller */
@media screen and (max-width: 960px) {
  .layout-menu-button {
    display: none;
  }

  .layout-topbar-mobile-button {
    display: inline-flex;
  }

  .layout-menu-container {
    transform: translateX(-100%);
  }

  .layout-main {
    margin-left: 0;
  }

  .layout-wrapper.layout-menu-active {
    overflow: hidden;
  }

  .layout-wrapper.layout-menu-active .layout-menu-container {
    transform: translateX(0);
  }

  .layout-wrapper.layout-menu-active .layout-mask {
    display: block;
  }

  /* Login Responsive */
  .login-container {
    flex-direction: column;
  }

  .login-left {
    padding: 2rem;
    min-height: 300px;
  }

  .login-left h1 {
    font-size: 2.5rem;
  }

  .login-copyright {
    position: relative;
    bottom: auto;
    margin-top: 2rem;
  }
}

/* Dark Mode Stiller */
.dark .layout-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.03);
}

.dark .layout-mask {
  background-color: rgba(0, 0, 0, 0.6);
}

.dark .login-right {
  background-color: var(--surface-card);
}

.dark .login-box .p-inputtext,
.dark .login-box .p-password input {
  background-color: var(--surface-section);
  border-color: var(--surface-border);
  color: var(--text-color);
}

.dark .google-button {
  background-color: var(--surface-section);
  border-color: var(--surface-border);
  color: var(--text-color);
} 